/**
 * @description A list of supported cities (they can be treated as city ids)
 * where we provide services.
 *  - They are lowercase due to easier/saner fuse.js search implementation
 *  - Use function `getSupportedCitites` if you want to use the city list
 *  in a form. The function will append a production test city for admins.
 */
export const SUPPORTED_CITIES_B2B = [
  { name: 'ancona', lng: 13.5168133, lat: 43.6166489 },
  { name: 'avellino', lng: 14.7911166, lat: 40.9144676 },
  { name: 'bergamo', lng: 9.668711, lat: 45.6941984 },
  { name: 'bologna', lng: 11.3426, lat: 44.4932318 },
  { name: 'brescia', lng: 10.2156823, lat: 45.5403137 },
  { name: 'firenze', lng: 11.2463453, lat: 43.7751269 },
  { name: 'foggia', lng: 15.5406353, lat: 41.462094 },
  { name: 'genova', lng: 8.9097227, lat: 44.4083582 },
  { name: 'lecce', lng: 18.1666213, lat: 40.3514381 },
  { name: 'milano', lng: 9.18854, lat: 45.464664 },
  { name: 'monza', lng: 9.2735078, lat: 45.583547 },
  { name: 'napoli', lng: 14.2681, lat: 40.8518 },
  { name: 'padova', lng: 11.8713873, lat: 45.4089257 },
  { name: 'roma', lng: 12.4841008, lat: 41.8962146 },
  { name: 'siena', lng: 11.3271333, lat: 43.3191518 },
  { name: 'torino', lng: 7.6778283, lat: 45.0712738 },
  { name: 'varese', lng: 8.8209043, lat: 45.8180868 },
  { name: 'palermo', lng: 13.3548028, lat: 38.1142286 },
  { name: 'catania', lng: 15.0850115, lat: 37.5023308 },
  { name: 'siracusa', lng: 15.2755113, lat: 37.0921263 },
  { name: 'agrigento', lng: 13.585126, lat: 37.30921 },
  { name: 'messina', lng: 15.5542, lat: 38.1937 },
  { name: 'olbia', lng: 9.5, lat: 40.916668 },
  { name: 'cosenza', lng: 16.2523086, lat: 39.3048001 },
  { name: 'ancona', lng: 13.4655414, lat: 43.5821642 },
  { name: 'siracusa', lng: 15.250254, lat: 37.0791471 },
  { name: 'calabria', lng: 15.6478516, lat: 38.1109851 },
  { name: 'pisa', lng: 10.4018624, lat: 43.7159395 },
  { name: 'vicenza', lng: 11.55, lat: 45.549999 },
  { name: 'PRODUCTION_TEST_CITY', lng: 9.18854, lat: 45.464664 },
];

/**
 * @description returns a PRODUCTION_TEST_CITY if the user is an admin
 */
export const getSupportedB2BCities = (isAdmin: boolean) => {
  const cities = SUPPORTED_CITIES_B2B.map((c) => c.name);

  if (!isAdmin) {
    cities.splice(cities.indexOf('PRODUCTION_TEST_CITY'), 1);
  }

  return cities;
};
